<template>
    <div class="bg re_sizing">
        <div class="title re_relative re_flex_center re_font_18">
            <van-icon @click="goBack()" class="icon" name="arrow-left" size="22" />
        </div>
        <div class="avatar re_relative">
            <div @click="showData = true" class="time re_font_bold re_font_18 re_flex_center">
                <span>{{currentDate[0]}}年</span>
                <img class="re_margin_l_8" src="../../../assets/appleTree/arrow.png" alt="">
            </div>
            <div class="re_absolute userInfo re_flex_center">
                <img class="photo" :src="info.photo" alt="">
                <div class="re_margin_l_8">
                    <div class="re_font_16 re_ellipsis nickName">{{info.nickName}}</div>
                    <div class="re_font_12">{{info.level}}</div>
                </div>
                <img v-if="info.stateText" class="medal" :src="require(`../../../assets/appleTree/state${info.state}.png`)" alt="">
            </div>
        </div>
        <div class="content re_sizing re_flex c2 re_relative" v-if="info.isTree === 1">
            <div class="appleBox" v-if="info.goldenAppleNum >= 1">
                <img src="../../../assets/appleTree/medal5.png" alt="">
                <div class="re_font_14">获得</div>
                <div class="re_font_14">
                    <span class="green">金苹果</span>
                    <span class="re_color_red re_font_bold re_font_18 re_margin_l_8">X{{info.goldenAppleNum}}</span>
                </div>
            </div>
            <div v-if="info.otherStateNum == 1 && info.state != 5" class="appleBox">
                <img :src="require(`../../../assets/appleTree/medal${info.state}.png`)" alt="">
                <div class="re_font_14">获得</div>
                <div class="re_font_14">
                    <span class="green">{{info.stateText}}</span>
                    <span class="re_color_red re_font_bold re_font_18 re_margin_l_8">X{{info.otherStateNum}}</span>
                </div>
            </div>
        </div>
        <div class="content re_sizing re_flex_center c4 re_relative" v-else>
            <p>果园还未到摘果期, 暂无勋章</p>
        </div>
        <van-popup v-model:show="showData" position="bottom" >
            <van-date-picker
                v-show="showData"
                v-model="currentDate"
                :min-date="minDate"
                :max-date="maxDate"
                title="选择年"
                @cancel="showData = false"
                @confirm="getYear"
                :columns-type="columnsType"
            />
        </van-popup>
    </div>
</template>

<script setup>
var obj = {};
var surl = window.location.href;
var urls = surl.substr(surl.indexOf('?') + 1).split('&');
for (var i = 0; i < urls.length; i++) {
  var temp = urls[i].split('=');
 obj[temp[0]] = temp[1];
}
import { getHistoryAppleTree } from './api'
import { ref, onMounted } from 'vue';
import { router } from '../../router';

const currentDate = ref(['2025']);
const maxDate = new Date(2025, 5, 1);

const minDate = new Date(2018, 5, 1);

const columnsType = ['year'];

const showData = ref(false)
const info = ref({})
console.log(obj)
const getInit = () => {
    console.log(currentDate.value[0])
    getHistoryAppleTree({
        year: currentDate.value[0],
        userId: obj.owner || ''
    }).then(res => {
        if (res.code == 1) {
            switch(res.data.state){
                case 0:
                    res.data.stateText = '种子'
                    break;
                case 1:
                    res.data.stateText = '发芽'
                    break;
                case 2:
                    res.data.stateText = '苹果树'
                    break;
                case 3:
                    res.data.stateText = '青苹果'
                    break;
                case 4:
                    res.data.stateText = '红苹果'
                    break;
                case 5:
                    res.data.stateText = '金苹果'
                    break;
            }
            info.value = res.data
        }
    })
}
getInit()
const getYear = () => {
    showData.value = false
    getInit()
}
const goBack = () => {
    router.go(-1)
}
</script>

<style lang="less" scoped>

.bg {
    width: 100%;
    height: 100vh;
    padding-top: 50px;
    background: url('../../../assets/appleTree/mymedal.jpg') left top no-repeat;
    background-size: 100% 100%;

    .title {
        width: 100%;
        font-weight: 600;
        z-index: 9;

        span {
            left: 10px;
        }

        .icon {
            position: absolute;
            left: 18px;
            top: 2px;
        }
    }
}
.avatar{
    top: 5%;
    .time{
        width: 40%;
        height: 7vh;
        background: url('../../../assets/appleTree/timeBg.png') left top no-repeat;
        background-size: 100% 100%;
        margin: 0 auto;
        color: #F92C03;
        img{
            width: 10px;
            height: 5px;
        }
    }
    .userInfo{
        width: 70%;
        height: 10vh;
        // background-color: #0C8045;
        left: 15%;
        top: 84%;
    }
    .photo{
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 3px #0C8045 solid;
    }
    .nickName{
        width: 134px;
    }
    .medal{
        width: 50px;
        height: 40px;
    }
}
.c2{
    padding: 70px 30px 0 30px;
    height: 208px;
    background: url('../../../assets/appleTree/two.png') left top no-repeat;
}
.c4{
    height: 380px;
    background: url('../../../assets/appleTree/four.png') left top no-repeat;
}
.content{
    width: 100%;
    background-size: 100% 100%;
    top: 12%;
    img{
        width: 96px;
        height: 53px;
    }
    .appleBox{
        width: 50%;
        text-align: center;
    }
    .green{
        color: #125D31;
    }
}
</style>